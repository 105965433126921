// console.log('price functions');


$(document).ready(function(){

    $('.service').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

});
