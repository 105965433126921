// console.log('homepage slider js file');

$(document).ready(function($) {

    // Wait till the slider script and images have loaded before executing the slider
    $('.hollandandco-slider').css('visibility', 'visible').show().unslider({
        infinite: false,
        autoplay: true,
        animation: 'fade',
        speed: 1000,
        delay: 6000,
        arrows: {
            //  Unslider default behaviour
            prev: '<a class="unslider-arrow prev"></a>',
            next: '<a class="unslider-arrow next"></a>',
        }

    });


    $('.unslider-arrow-down').on("click", function(e){

        // prevent default action and bubbling
        e.preventDefault();
        e.stopPropagation();
        // set target to anchor's "href" attribute
        var target = $(this).attr('href');
        // scroll to each target
        $(target).velocity('scroll', {
            duration: 500,
            offset: -20,
            easing: 'ease-in-out'
        });

    });


});
