// console.log('navigation js file');

$(document).ready(function(){

   var navigation = (function() {

      //  var navicon = $('.holland_hamburger');
       var navicon = $('button.hamburger');
       var holland_number = $('.holland_number');
       var holland_logo_container = $('.holland_logo');
       var navi_links = $(".menu_options ul");


      navicon.on("click", function(){

         $(this).toggleClass('is-active');
         $('nav#main_nav').toggleClass('menu-active');
         holland_logo_container.find("img").toggleClass('logo-hide');
         navi_links.children('li').toggleClass('fall_in');

      });


      $(window).scroll(function() {
          if($(window).scrollTop() > 0) {
              $('.logo-l').hide();
          } else {
              $('.logo-l').show();
          }
      });


  })();

});
