// console.log("Form.js Loaded");

$(document).ready(function(){

   var contactForm = (function(){

       // Get the form.
        var form = $('#hollandco_contact');

        // Get the messages div.
        var formMessages = $('.flash-msg');

        var formErrorLogger = $('#form-messages');

        var subHeading = $('.subHeading');
        var mainSubHeadingText = subHeading.text();

        // Set up an event listener for the contact form.
        $(document).on('submit', form , function(e) {
            // Stop the browser from submitting the form.
            e.preventDefault();

            // Serialize the form data.
            var formData = $(form).serialize();

            // Submit the form using AJAX.
            $.ajax({
                type: 'POST',
                url: $(form).attr('action'),
                data: formData
            })
            .done(function(response) {
                // Make sure that the formMessages div has the 'success' class.
                $(formMessages).removeClass('error-msg');
                $(formMessages).addClass('success-msg');

                // Set the message text.
                $(formMessages).html('<div>' +
                                        '<p><span class="flash-text">' +
                                            '<strong><i class="fa fa-paper-plane" aria-hidden="true"></i> Success!</strong><br /><p style="#000000">Thank you for getting in touch with us. We will be in touch shortly!</p>' +
                                        '</span></p>' +
                                     '</div>');

                if(subHeading.text() !== mainSubHeadingText){
                    subHeading.text(mainSubHeadingText);
                }

                $(formErrorLogger).children().remove();

                // Clear the form.
                $('#name').val('');
                $('#email').val('');
                $('#telephone').val('');
                $('#subject').val('');
                $('#message').val('');

                subHeading.hide();
                form.hide();

            })
            .fail(function(data) {
                // Make sure that the formMessages div has the 'error' class.
                $(formMessages).removeClass('success-msg');
                $(formMessages).addClass('error-msg');

                // Set the message text.
                if (data.responseText !== '') {
                    $(subHeading).text('Please address the following issues:');
                    $(formErrorLogger).html(data.responseText);
                    $(formMessages).html('<p><span class="flash-text"><strong><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></strong></span></p>');
                } else {
                    $(formMessages).html('<p>Oops! An error occured and your message could not be sent.' + data + '</p>');
                }
            });

        });

   });

   contactForm();
});
